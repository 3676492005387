import { Button, Checkbox, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import liff from "@line/liff/dist/lib";
import MainLayout from "components/layouts/MainLayout";
import UserForms from "features/user/components/UserForms";
import { userScheme } from "features/user/libs/schemes";
import { UserFormInputs } from "features/user/types";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

const PAGE_TITLE = "会員登録";

const SignupNew: FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const defaultValues: UserFormInputs = {
    last_name: state?.last_name,
    first_name: state?.first_name,
    postal_code: state?.postal_code,
    prefecture: state?.prefecture,
    municipalities: state?.municipalities,
    after_address: state?.after_address,
    building: state?.building,
    paper_flier_genres: state?.paper_flier_genres || [],
    terms: false,
  };

  const methods = useForm<UserFormInputs>({
    mode: "onBlur",
    defaultValues: defaultValues,
    resolver: yupResolver(userScheme),
  });
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid },
  } = methods;

  const onConfirm = (values: UserFormInputs) => {
    navigate("/signup/confirm", {
      state: {
        last_name: values.last_name,
        first_name: values.first_name,
        postal_code: values.postal_code,
        prefecture: values.prefecture,
        municipalities: values.municipalities,
        after_address: values.after_address,
        building: values.building,
        paper_flier_genres: values?.paper_flier_genres || [],
      },
    });
  };

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE}>
      <Heading fontSize="1rem">{PAGE_TITLE}</Heading>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onConfirm)}>
          <UserForms />

          <Flex as="label" mt="2.375rem" justifyContent="center" fontSize="md">
            <Checkbox mr="0.5rem" {...register("terms")} />
            <Text>
              <Link
                as="button"
                type="button"
                fontWeight="bold"
                color="Primary/primary"
                onClick={() =>
                  liff.openWindow({
                    url: "https://funpost.fun/terms.html",
                    external: false,
                  })
                }
              >
                会員規約
              </Link>
              を確認して同意します。
            </Text>
          </Flex>

          <Button
            type="submit"
            mt="2rem"
            variant="solid"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            確認する
          </Button>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default SignupNew;
