import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { useGetPaperFlierGenres } from "api";
import { UserFormInputs } from "features/user/types";
import { PREFECTURES } from "libs/constants";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { usePostalJp } from "use-postal-jp";

const REQUIRED = "※入力してください。";

const UserForms: FC = () => {
  const { data: paperFlierGenres, isLoading } = useGetPaperFlierGenres();

  const {
    formState: { errors },
    register,
    watch,
    setValue,
    getFieldState,
  } = useFormContext<UserFormInputs>();

  // 郵便番号入力欄を監視
  const { isDirty } = getFieldState("postal_code");
  const postalCode = watch("postal_code", "");
  // 郵便番号から住所取得
  const [address] = usePostalJp(
    postalCode,
    isDirty && postalCode.length === 7 // 入力内容に変更がある かつ 7ケタ であれば住所取得を実行
  );

  // 取得した住所に変更があれば、都道府県、市区町村に値をセット
  useEffect(() => {
    if (
      !(
        address &&
        // 取得した住所の都道府県がプルダウン選択肢に存在するかチェック
        PREFECTURES.find(({ value }) => value === address.prefecture)
      )
    )
      return;

    // 都道府県
    setValue("prefecture", address.prefecture);
    // 市区町村
    setValue("municipalities", `${address.address1}${address.address2}`);
  }, [setValue, address]);

  return (
    <VStack spacing="1rem" mt="1rem">
      <Flex w="100%">
        <FormControl isInvalid={errors.last_name ? true : false}>
          <FormLabel>姓</FormLabel>
          <Input type="text" placeholder="新規" {...register("last_name")} />
          {errors.last_name && <FormErrorMessage>{REQUIRED}</FormErrorMessage>}
        </FormControl>

        <FormControl ml="0.5rem" isInvalid={errors.first_name ? true : false}>
          <FormLabel>名</FormLabel>
          <Input type="text" placeholder="登録" {...register("first_name")} />
          {errors.first_name && <FormErrorMessage>{REQUIRED}</FormErrorMessage>}
        </FormControl>
      </Flex>

      <FormControl isInvalid={errors.postal_code ? true : false}>
        <FormLabel>郵便番号</FormLabel>
        <Input type="tel" placeholder="1234567" {...register("postal_code")} />
        {errors.postal_code && (
          <FormErrorMessage>
            ※ハイフン（-）なしの半角７桁の数字を入力してください。
          </FormErrorMessage>
        )}
        <FormHelperText>※ハイフン（-）は不要です。</FormHelperText>
      </FormControl>

      <FormControl isInvalid={errors.prefecture ? true : false}>
        <FormLabel>都道府県</FormLabel>
        <Select {...register("prefecture")}>
          {PREFECTURES.map((prefecture) => {
            return (
              <option key={prefecture.id} value={prefecture.value}>
                {prefecture.value}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <FormControl isInvalid={errors.municipalities ? true : false}>
        <FormLabel>市区町村</FormLabel>
        <Input
          type="text"
          placeholder="〇〇市"
          {...register("municipalities")}
        />
        {errors.municipalities && (
          <FormErrorMessage>{REQUIRED}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={errors.after_address ? true : false}>
        <FormLabel>番地</FormLabel>
        <Input
          type="text"
          placeholder="１−１−１"
          {...register("after_address")}
        />
        {errors.after_address && (
          <FormErrorMessage>{REQUIRED}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl>
        <FormLabel>建物名・部屋番号</FormLabel>
        <Input type="text" placeholder="" {...register("building")} />
      </FormControl>

      <FormControl>
        <FormLabel>紙で受け取りたいチラシ</FormLabel>
        <Skeleton isLoaded={!isLoading}>
          <VStack alignItems="start">
            {paperFlierGenres &&
              Object.entries(paperFlierGenres).map(([genreKey, genreName]) => (
                <Checkbox
                  key={genreKey}
                  // 選択したチラシジャンル名を確認ページで表示するため、チラシジャンル名もvalueに含める
                  value={JSON.stringify({ [genreKey]: genreName })}
                  {...register("paper_flier_genres")}
                >
                  {genreName}
                </Checkbox>
              ))}
          </VStack>
        </Skeleton>
      </FormControl>
    </VStack>
  );
};

export default UserForms;
