import { boolean, object, string } from "yup";

/**
 * 7桁の半角数字
 * - /:正規表現対象の値を囲む
 * - ^:データの行の先頭
 * - $:続いてデータの行の末尾
 * cf.https://developer.mozilla.org/ja/docs/Web/JavaScript/Guide/Regular_Expressions
 */
const postalCodeRegExp = /^([0-9]{7})$/;

export const userScheme = object().shape({
  last_name: string().required(),
  first_name: string().required(),
  postal_code: string().required().matches(postalCodeRegExp),
  prefecture: string().required(),
  municipalities: string().required(),
  after_address: string().required(),
  building: string(),
  terms: boolean().isTrue(),
});
