import { useGetCurrentUserOnce } from "api";
import Loading from "components/modules/Loading";
import { utmParametersState } from "libs/recoil/atom";
import Flier from "pages/flier/Flier";
import Fliers from "pages/flier/Fliers";
import FliersOfPublisher from "pages/flier/FliersOfPublisher";
import Notification from "pages/notification/Notification";
import Notifications from "pages/notification/Notifications";
import Point from "pages/point/Point";
import PointExchangeComplete from "pages/point/PointExchangeComplete";
import PointExchangeConfirm from "pages/point/PointExchangeConfirm";
import PointExchangeNew from "pages/point/PointExchangeNew";
import SignupComplete from "pages/signup/SignupComplete";
import SignupConfirm from "pages/signup/SignupConfirm";
import SignupNew from "pages/signup/SignupNew";
import SignupTop from "pages/signup/SignupTop";
import AboutFunPost from "pages/user-menu/AboutFunPost";
import Contact from "pages/user-menu/Contact";
import FAQ from "pages/user-menu/FAQ";
import User from "pages/user-menu/User";
import UserEdit from "pages/user-menu/UserEdit";
import UserMenu from "pages/user-menu/UserMenu";
import UserWithdraw from "pages/user-menu/UserWithdraw";
import { FC } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useSetRecoilState } from "recoil";

const AppRoutes: FC = () => {
  const { error, isLoading } = useGetCurrentUserOnce();
  const [searchParams] = useSearchParams();
  const setUtmParameters = useSetRecoilState(utmParametersState);
  /** 会員登録・未登録判定フラグ */
  const isUnsigned = error?.errors.includes("登録されていないユーザです");

  // 初回アクセス時の UTM パラメータを記録しておく
  useEffectOnce(() =>
    setUtmParameters({
      utm_campaign: searchParams.get("utm_campaign"),
      utm_content: searchParams.get("utm_content"),
      utm_medium: searchParams.get("utm_medium"),
      utm_source: searchParams.get("utm_source"),
    })
  );

  if (isLoading) return <Loading header={false} />;

  return (
    <Routes>
      {/* 会員登録 */}
      <Route
        path="/signup/top"
        // 会員登録済みユーザーがアクセスした場合、重複登録できないようにするためにチラシ一覧へリダイレクト
        element={isUnsigned ? <SignupTop /> : <Navigate to="/fliers" />}
      />
      <Route
        path="/signup/new"
        element={isUnsigned ? <SignupNew /> : <Navigate to="/fliers" />}
      />
      <Route
        path="/signup/confirm"
        element={isUnsigned ? <SignupConfirm /> : <Navigate to="/fliers" />}
      />
      <Route path="/signup/complete" element={<SignupComplete />} />

      {/* 会員管理 */}
      <Route path="/user-menu" element={<UserMenu />} />
      <Route path="/user-menu/about-funpost" element={<AboutFunPost />} />
      <Route path="/user-menu/faq" element={<FAQ />} />
      <Route path="/user-menu/contact" element={<Contact />} />
      {/* 会員情報 */}
      <Route path="/user-menu/user" element={<User />} />
      {/* 会員情報編集 */}
      <Route path="/user-menu/user/edit" element={<UserEdit />} />
      {/* 退会 */}
      <Route path="/user-menu/user/withdraw" element={<UserWithdraw />} />

      {/* チラシ */}
      <Route path="/fliers" element={<Fliers />} />
      <Route path="/fliers/:deliveryId" element={<Flier />} />
      {/* 広告主ごとのチラシ一覧 */}
      <Route
        path="/fliers/publishers/:publisherId"
        element={<FliersOfPublisher />}
      />

      {/* ポイント */}
      <Route path="/point" element={<Point />} />
      <Route path="/point/exchange/new" element={<PointExchangeNew />} />
      <Route
        path="/point/exchange/confirm"
        element={<PointExchangeConfirm />}
      />
      <Route
        path="/point/exchange/complete"
        element={<PointExchangeComplete />}
      />

      {/* お知らせ */}
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/notifications/:notificationId" element={<Notification />} />

      <Route
        path="/"
        element={
          // api/index.tsx内のエラーハンドリングでは一瞬チラシ一覧ページが表示されてしまうため、ここでもハンドリングする
          isUnsigned ? <Navigate to="/signup/top" /> : <Navigate to="/fliers" />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
