import { Flex, Link, Text } from "@chakra-ui/react";
import { useGetIsExistUnreadNotification } from "api";
import {
  NotificationIcon,
  PointIcon,
  PostIcon,
  SelectedNotificationIcon,
  SelectedPointIcon,
  SelectedPostIcon,
  SelectedUserIcon,
  UserIcon,
} from "assets/icons/Icons";
import { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import IconWithBadge from "./IconWithBadge";

type FooterItemProps = {
  to: string;
  label: string;
  icon: JSX.Element;
  isSelected: boolean;
};

const FooterItem: FC<FooterItemProps> = ({ to, label, icon, isSelected }) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      flex={1}
      h="full"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      color={isSelected ? "Primary/primary" : "black"}
      _hover={{
        textDecoration: "none",
      }}
    >
      {icon}
      <Text fontSize="md" fontWeight="500">
        {label}
      </Text>
    </Link>
  );
};

const Footer: FC = () => {
  const { pathname } = useLocation();
  const firstPath = pathname.split("/")[1];
  const { data: { is_exist_unread } = { is_exist_unread: false } } =
    // ページ遷移する度にFooterコンポーネントが読み込まれるため、古いデータがある場合、自動再検証しない https://swr.vercel.app/ja/docs/revalidation#disable-automatic-revalidations
    useGetIsExistUnreadNotification({
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  return (
    <Flex
      as="footer"
      w="100%"
      h="4rem"
      bg="white"
      boxShadow="0 -10px 15px 3px rgba(0, 0, 0, 0.1),0 -4px 6px 2px rgba(0, 0, 0, 0.05)" // `lg` の上側に影が伸びるバージョン
      position="sticky"
      bottom={0}
      justifyContent="center"
      alignItems="center"
    >
      {/* ポスト */}
      <FooterItem
        to="/fliers"
        label="ポスト"
        icon={
          firstPath === "fliers" ? (
            <SelectedPostIcon boxSize="1.25rem" />
          ) : (
            <PostIcon boxSize="1.25rem" />
          )
        }
        isSelected={firstPath === "fliers"}
      />
      {/* ポイント */}
      <FooterItem
        to="/point"
        label="ポイント"
        icon={
          firstPath === "point" ? (
            <SelectedPointIcon boxSize="1.25rem" />
          ) : (
            <PointIcon boxSize="1.25rem" />
          )
        }
        isSelected={firstPath === "point"}
      />
      {/* お知らせ */}
      <FooterItem
        to="/notifications"
        label="お知らせ"
        icon={
          <IconWithBadge
            icon={
              firstPath === "notifications" ? (
                <SelectedNotificationIcon boxSize="1.25rem" />
              ) : (
                <NotificationIcon boxSize="1.25rem" />
              )
            }
            hasBadge={is_exist_unread}
          />
        }
        isSelected={firstPath === "notifications"}
      />
      {/* 会員 */}
      <FooterItem
        to="/user-menu"
        label="会員"
        icon={
          firstPath === "user-menu" ? (
            <SelectedUserIcon boxSize="1.25rem" />
          ) : (
            <UserIcon boxSize="1.25rem" />
          )
        }
        isSelected={firstPath === "user-menu"}
      />
    </Flex>
  );
};

export default Footer;
