import { Button, Heading, Text } from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import MainLayout from "components/layouts/MainLayout";
import { getErrorTexts } from "libs/handleError";
import { FC } from "react";

const Error: FC = () => {
  const paramStr = window.location.search;
  const { title, description } = getErrorTexts(paramStr);

  return (
    <MainLayout header={false} footer={false} pageTitle={title}>
      <Heading fontSize="1rem">{title}</Heading>
      <Text mt="1rem" textAlign="center">
        {description}
      </Text>
      <Button position="sticky" top="100%" onClick={() => liff.closeWindow()}>
        閉じる
      </Button>
    </MainLayout>
  );
};

export default Error;
