import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import { usePostCurrentPointHistories } from "api";
import { PolygonIcon } from "assets/icons/Icons";
import MainLayout from "components/layouts/MainLayout";
import LabeledValue from "components/modules/LabeledValue";
import AboutDigicoCard from "features/point/components/AboutDigicoCard";
import { ExchangeConfirmLocation } from "features/point/types";
import { useCustomToast } from "hooks/useCustomToast";
import { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";

const PointExchangeConfirm: FC = () => {
  const navigate = useNavigate();
  const { pathname, state }: ExchangeConfirmLocation = useLocation();
  const { trigger } = usePostCurrentPointHistories();
  const toast = useCustomToast();

  // 交換するボタンクリック時の処理 ※ダブルクリック対策のため useAsyncFn を利用して処理が完全に終了するまでの状態を管理
  const [{ loading: isMutating }, onClickButton] = useAsyncFn(async () => {
    if (!state) return;
    const data = await trigger(
      {
        points: state.consumption_points,
        request_token: state.request_token,
      },
      {
        throwOnError: true, // 成功か失敗を判断するため true
        // 失敗時の処理はここで行う(reject で受け取るエラーには型がつかないためしょうがなく)
        onError: ({ errors }) => toast(errors, { status: "error" }), // トーストエラー
      }
    );
    navigate(pathname, { replace: true }); // state を削除
    navigate("/point/exchange/complete", { state: { ...data } }); // 完了ページへ
  }, [state, trigger]);

  // state が正しく取得できない場合は /point/exchange/new にもどってやり直し
  if (!state) return <Navigate to="/point/exchange/new" />;

  return (
    <MainLayout header pageTitle="ポイント交換">
      <Stack spacing="1.5rem">
        {/* デジコについて */}
        <AboutDigicoCard />
        {/* 交換内容 */}
        <Stack spacing="0.5rem">
          <Text fontWeight="bold">交換内容</Text>
          <Flex alignItems="center" columnGap="0.5rem">
            {/* 交換後するポイント */}
            <Flex
              justifyContent="center"
              alignItems="baseline"
              py="1.5rem"
              flex={1}
              borderWidth="2px"
              borderColor="System Gray/300"
              borderRadius="0.625rem"
            >
              <Text fontSize="2xl" fontWeight="bold">
                {state.consumption_points.toLocaleString()}
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                ポイント
              </Text>
            </Flex>
            {/* → */}
            <PolygonIcon boxSize="0.75rem" />
            {/* デジコ交換後の相当額 */}
            <Flex
              justifyContent="center"
              alignItems="baseline"
              py="1.5rem"
              flex={1}
              borderWidth="2px"
              borderColor="System Gray/300"
              borderRadius="0.625rem"
            >
              <Text fontSize="2xl" fontWeight="bold">
                {state.grant_digico_points.toLocaleString()}
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                円相当
              </Text>
            </Flex>
          </Flex>
        </Stack>
        {/* 交換後ポイント数 */}
        <LabeledValue
          label="交換後ポイント数"
          value={`${state.balance_after_exchange.toLocaleString()}ポイント`}
        />
      </Stack>
      {/* 交換するボタン */}
      <Button
        mt="1.5rem"
        position="sticky"
        top="100%"
        onClick={onClickButton}
        isLoading={isMutating}
      >
        デジコに交換する
      </Button>
    </MainLayout>
  );
};

export default PointExchangeConfirm;
