import { Button, Heading, Link, Stack } from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import MainLayout from "components/layouts/MainLayout";
import LabeledValue from "components/modules/LabeledValue";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ExchangeCompleteLocation } from "features/point/types";
import { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const PointExchangeComplete: FC = () => {
  const navigate = useNavigate();
  const { pathname, state }: ExchangeCompleteLocation = useLocation();

  // state が正しく取得できない場合は /point/exchange/new にもどってやり直し
  if (!state) return <Navigate to="/point/exchange/new" />;

  // ポイントに戻るボタンクリック
  const onClickBackButton = () => {
    navigate(pathname, { replace: true }); // state を削除
    navigate("/point");
  };

  return (
    <MainLayout header footer pageTitle="交換が完了しました">
      <Stack spacing="1rem">
        {/* タイトル */}
        <Heading as="h2" size="md" textAlign="center" lineHeight="2rem">
          交換が完了しました。
        </Heading>
        {/* 完了情報 */}
        <Stack spacing="1.5rem">
          {/* 交換日 */}
          <LabeledValue
            label="交換日"
            value={dayjs(state.point_history.created_at).format("YYYY年M月D日")}
          />
          {/* 交換内容 */}
          <LabeledValue
            label="交換内容"
            value={`${Math.abs(
              state.point_history.point
            ).toLocaleString()}ポイント`}
          />
          {/* 交換後ポイント数 */}
          <LabeledValue
            label="交換後ポイント数"
            value={`${state.balance.toLocaleString()}ポイント`}
          />
          {/* デジコ交換後金額 */}
          <LabeledValue
            label="デジコ交換後金額"
            value={`${state.point_history.digico_gift_identify_code.toLocaleString()}円相当`}
          />
          {/* ギフトURL */}
          <LabeledValue
            label="ギフトURL"
            value={
              <Link
                onClick={() =>
                  liff.openWindow({
                    url: state.point_history.digico_url,
                    external: true,
                  })
                }
              >
                {state.point_history.digico_url}
              </Link>
            }
          />
          {/* 有効期限 */}
          <LabeledValue
            label="有効期限"
            value={dayjs(state.point_history.expire_date).format(
              "YYYY年M月D日"
            )}
          />
          {/* ポイントトップに戻るボタン */}
          <Button onClick={onClickBackButton}>ポイントトップへ</Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default PointExchangeComplete;
