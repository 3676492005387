import {
  AlertStatus,
  Flex,
  Stack,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { CheckCircleIcon, XCircleIcon } from "assets/icons/Icons";

/**
 * Chakra UI の Toast をカスタマイズしたもの
 *
 * @returns toast 関数
 */
export const useCustomToast = () => {
  const toast = useToast({
    position: "top",
    isClosable: false,
    render: ({ status, description }) => (
      <Flex
        p="0.75rem"
        columnGap="0.375rem"
        justifyContent="center"
        alignItems="center"
        fontWeight="bold"
        fontSize="md"
        {...(status === "success" ? { bg: "#6AB864E5", color: "white" } : {})}
        {...(status === "error" ? { bg: "#DA6464E5", color: "white" } : {})}
        {...(status === "info" ? { bg: "#525252E5", color: "white" } : {})}
      >
        {/* icon */}
        {status === "success" && <CheckCircleIcon boxSize="1.125rem" />}
        {status === "error" && <XCircleIcon boxSize="1.125rem" />}
        {status === "info" && null}
        {/* description */}
        <Stack spacing="0.25rem" textAlign="center">
          {description}
        </Stack>
      </Flex>
    ),
    containerStyle: {
      maxW: "3xl", // 横幅の上限を MainLayout に合わせる
      w: "full",
      mt: 0,
    },
  });

  return (
    message: string | string[], // 表示するメッセージ
    options: Pick<
      UseToastOptions,
      "position" | "duration" | "onCloseComplete" | "status" // その他カスタマイズ可能なオプションのみ抽出
    > & {
      status: Extract<AlertStatus, "success" | "error" | "info">; // success error info のみ
    }
  ) =>
    toast({
      ...options,
      description:
        typeof message === "string" ? (
          // メッセージが1つの場合
          <p>{message}</p>
        ) : (
          // メッセージが複数の場合
          <>
            {message.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </>
        ),
    });
};
