import { Button, Heading } from "@chakra-ui/react";
import { useGetCurrentUserOnce } from "api";
import MainLayout from "components/layouts/MainLayout";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const PAGE_TITLE = "会員登録が完了しました";

const SignupComplete: FC = () => {
  const navigate = useNavigate();
  const { mutate } = useGetCurrentUserOnce();

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE}>
      <Heading fontSize="1rem">{PAGE_TITLE}</Heading>
      <Button
        mt="2rem"
        position="sticky"
        top="100%"
        onClick={() => {
          navigate("/fliers");
          mutate(); // 401 エラー判定のままだと自動再検証が行われないのでここで強制的に再検証
        }}
      >
        ホームへ
      </Button>
    </MainLayout>
  );
};

export default SignupComplete;
