/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentStyleConfig, StyleFunctionProps } from "@chakra-ui/react";

/*
    NOTE: variant prop in Button Component.
    You can also use colorScheme prop that specifies primary color theme.
    e.g. <Button variant="solid" /> <Button variant="solid" colorScheme="primary" />
*/
export const Button: ComponentStyleConfig = {
  baseStyle: {
    width: "100%",
    transition: "all 0.2s cubic-bezier(.08,.52,.52,1)",
    textDecoration: "none",
    _focus: {
      outline: 0,
    },
    _active: {
      transform: "scale(0.98)",
      outline: 0,
    },
  },
  variants: {
    solid: (props: StyleFunctionProps) =>
      props.colorScheme === "primary"
        ? // colorScheme が "primary" のとき
          {
            bg: "Primary/primary",
            color: "System Gray/000",
            _hover: {
              opacity: "0.9",
            },
            _disabled: {
              bg: "System Gray/200",
              color: "System Gray/600",
              opacity: "unset",
              pointerEvents: "none",
            },
          }
        : // それ以外はデフォルト設定
          {},
    outline: (props: StyleFunctionProps) =>
      props.colorScheme === "primary"
        ? // colorScheme が "primary" のとき
          {
            bg: "System Gray/000",
            color: "Primary/primary",
            border: "1px",
            borderColor: "Primary/primary",
            _hover: {
              opacity: "0.9",
            },
            _disabled: {
              bg: "System Gray/100",
              opacity: "unset",
              pointerEvents: "none",
            },
          }
        : // それ以外はデフォルト設定
          {},
  },
  sizes: {
    md: {
      fontSize: "0.8125rem", // 13px
      fontWeight: 700,
      borderRadius: "0.5rem",
      height: "auto",
      py: "0.75rem",
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
    colorScheme: "primary",
  },
};

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    textAlign: "center",
    fontWeight: 700,
    // not applicable
    fontSize: "md",
    lineHeight: 1.6,
  },
};

export const Text: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 400,
    fontSize: "md",
    lineHeight: 1.6,
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  },
};

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    label: {
      // avoids console error, or Unable to preventDefault inside passive event listener invocation.
      // https://github.com/chakra-ui/chakra-ui/issues/2925
      pointerEvents: "none",
    },
    control: {
      _checked: {
        bg: "Primary/primary",
        borderColor: "Primary/primary",

        _hover: {
          bg: "Primary/primary",
          borderColor: "Primary/primary",
        },
      },
    },
  },
};

export const Tag: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "sm",
    borderRadius: "0.25rem",
  },
};
