import { Button, Heading } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useGetCurrentUser,
  useGetPaperFlierGenres,
  usePutCurrentUser,
} from "api";
import MainLayout from "components/layouts/MainLayout";
import UserForms from "features/user/components/UserForms";
import { convertUserFormInputs } from "features/user/libs/paperFlierGenres";
import { userScheme } from "features/user/libs/schemes";
import { UserFormInputs } from "features/user/types";
import { useCustomToast } from "hooks/useCustomToast";
import { FC, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const PAGE_TITLE = "会員情報編集";

const UserEdit: FC = () => {
  const { data } = useGetCurrentUser(
    // 初回マウント時の1回だけデータ取得
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const { data: paperFlierGenres } = useGetPaperFlierGenres();
  const { trigger } = usePutCurrentUser();
  const navigate = useNavigate();
  const toast = useCustomToast();

  // 会員情報編集入力フォーム
  const methods = useForm<UserFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(userScheme),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = methods;

  // 会員情報編集入力フォーム submit ※ダブルクリック対策のため Promise を返し、全ての処理が完全に終了するまで resolve させない
  const onSubmit: SubmitHandler<UserFormInputs> = (data) =>
    trigger(convertUserFormInputs(data).submitValues, {
      throwOnError: true, // 成功か失敗を判断するため true
      // 失敗時の処理はここで行う(reject で受け取るエラーには型がつかないためしょうがなく)
      onError: ({ errors }) => toast(errors, { status: "error" }), // トーストエラー
    })
      .then(
        // 成功時
        () => {
          // 成功時のトーストメッセージ
          toast("会員情報を更新しました", { status: "success" });
          // 会員情報ページへ
          navigate("/user-menu/user");
        }
      )
      .catch(
        // 失敗時 ※react-hook-form は reject 時に isSubmitting を true に戻してくれないので必ず catch する
        () => {
          /* 何もしない */
        }
      );

  // デフォルト値をセット
  useEffect(() => {
    if (data && paperFlierGenres)
      reset({
        last_name: data.last_name,
        first_name: data.first_name,
        postal_code: data.postal_code.replace("-", ""),
        prefecture: data.prefecture,
        municipalities: data.municipalities,
        after_address: data.after_address,
        building: data.building,
        paper_flier_genres: data.paper_media
          .filter((genre) => genre in paperFlierGenres)
          .map((genre) => JSON.stringify({ [genre]: paperFlierGenres[genre] })),
      });
  }, [data, paperFlierGenres, reset]);

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE}>
      <Heading size="md">{PAGE_TITLE}</Heading>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForms />
          <Button
            type="submit"
            mt="2rem"
            variant="solid"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            更新する
          </Button>
        </form>
      </FormProvider>
    </MainLayout>
  );
};

export default UserEdit;
