import { Button, Heading, Image, Stack, Text } from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import { useGetNotification, usePostReadNotifications } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { FC, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const PAGE_TITLE = "お知らせ詳細";

const Notification: FC = () => {
  const { notificationId } = useParams();
  const { data: notification, isValidating } = useGetNotification(
    notificationId || "",
    { revalidateOnFocus: false }
  );
  const { trigger: triggerPostReadNotifications } = usePostReadNotifications();

  // API017 お知らせ詳細 の取得が成功し、未読の場合、API018 お知らせ既読 で既読にする
  useEffect(() => {
    if (!notification?.id || notification.read) return;

    triggerPostReadNotifications(
      { notification_ids: [notification.id] },
      {
        throwOnError: false,
      }
    );
  }, [notification?.id, notification?.read, triggerPostReadNotifications]);

  if (isValidating) return <Loading pageTitle={PAGE_TITLE} />;

  // お知らせ詳細を取得できなかった場合は お知らせ一覧 にもどってやり直し(404)
  if (!notification) return <Navigate to="/notifications" />;

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE}>
      {/* お知らせ画像 */}
      {notification.image_filename && (
        <Image
          w="full"
          objectFit="contain"
          src={notification.image_filename}
          alt="お知らせ画像"
        />
      )}

      {/* お知らせタイトル */}
      <Heading size="md" textAlign="left" mt="1rem">
        {notification.title}
      </Heading>

      {/* 日時 */}
      <Text textAlign="right" mt="0.5rem">
        {dayjs(notification.published_at).format("YYYY年MM月DD日 HH:mm")}
      </Text>

      {/* お知らせ本文 */}
      <Text mt="2rem">{notification.description}</Text>

      {/* 詳細を確認するボタン */}
      <Stack mt="2rem" spacing="1rem" position="sticky" top="100%">
        {notification.external_links?.map((external_link) => (
          <Button
            key={external_link.url}
            whiteSpace="pre-wrap"
            wordBreak="break-all"
            onClick={() =>
              liff.openWindow({
                url: external_link.url,
                external: false,
              })
            }
          >
            {external_link.label}
          </Button>
        ))}
      </Stack>
    </MainLayout>
  );
};

export default Notification;
