import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Heading,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "assets/icons/Icons";
import MainLayout from "components/layouts/MainLayout";
import { FAQItem } from "features/user/types";
import { FC } from "react";

const PAGE_TITLE = "よくあるご質問";

const FAQItems: FAQItem[] = [
  {
    id: 1,
    title: "FunPostにチラシが届いていません。",
    description:
      "FunPostに賛同いただいた企業のチラシ、かつお住まいの地域が対象地域になる場合にチラシが届きますので、まだマッチするチラシがないということです。",
  },
  {
    id: 2,
    title: "自宅の郵便ポストには、たくさん紙チラシが届いていて減りません。",
    description:
      "FunPostもできるだけ多くの広告主に使ってもらえるよう、普及活動を頑張っていますが、まだまだです。応援よろしくお願いします。",
  },
  {
    id: 3,
    title: "FunPostのロゴ入り紙チラシがまだ郵便ポストに入ってきた。",
    description:
      "申し訳ございません。できるだけ１軒ずつ確認して郵便ポストへお届けしているのですが、誤って紙チラシが入ることがあります。FunPostに登録された住所にお間違えがなければ、FunPostの誤りになりますので、申し訳ございません。",
  },
];

const FAQ: FC = () => {
  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE}>
      <Heading fontSize="1rem">{PAGE_TITLE}</Heading>

      <Accordion mt="1.25rem" allowMultiple>
        {FAQItems.map((item) => {
          return (
            <AccordionItem key={item.id}>
              {/* AccordionButton button must be wrapped in an element with role heading.
              cf.https://chakra-ui.com/docs/components/accordion */}
              <h2>
                <AccordionButton
                  h="3.25rem"
                  display="flex"
                  justifyContent="space-between"
                  columnGap="1rem"
                  fontSize="sm"
                  fontWeight="bold"
                  textAlign="left"
                >
                  {item.title}
                  <ChevronDownIcon w="0.75rem" h="0.375rem" />
                </AccordionButton>
              </h2>
              <AccordionPanel pb="1rem" fontSize="sm">
                {item.description}
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </MainLayout>
  );
};

export default FAQ;
