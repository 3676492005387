import { Flex, Image, Text } from "@chakra-ui/react";
import digico_logo from "assets/images/digico_logo.png";
import { FC } from "react";

const AboutDigicoCard: FC = () => {
  return (
    <Flex
      alignItems="center"
      columnGap="1rem"
      p="1rem"
      borderWidth="1px"
      borderColor="System Gray/300"
      borderRadius="lg"
    >
      {/* デジコ画像 */}
      <Image width="40%" src={digico_logo} alt="デジコロゴ画像" />
      {/* デジコ説明 */}
      <Text width="60%" fontSize="0.75rem" color="System Gray/700">
        交換先を選べるデジタルギフトです。 <br />
        Amazonギフトカード・Apple Gift Card・Google Play
        ギフトコード・QUOカードPay・LINE
        Pay・PeXポイントギフトと交換することができます。ポイント交換サービス「PeX」のPeXポイントギフトに交換すると、Tポイントやdポイント、JALのマイルなど最大6,000種類のサービスに交換も可能です。
      </Text>
    </Flex>
  );
};

export default AboutDigicoCard;
