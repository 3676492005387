import {
  Badge,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import {
  useDeleteFavoriteDelivery,
  useGetDelivery,
  usePostFavoriteDelivery,
  usePostFlierPointHistories,
} from "api";
import {
  FavoriteOffIcon,
  FavoriteOnIcon,
  SelectedPointIcon,
} from "assets/icons/Icons";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import LogoWithText from "components/modules/LogoWithText";
import FlierProducts from "features/flier/components/FlierProducts";
import { useCustomToast } from "hooks/useCustomToast";
import { FC, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link as RouterLink, Navigate, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const Flier: FC = () => {
  const { deliveryId = "" } = useParams();
  const {
    data: delivery,
    isLoading,
    isValidating,
    mutate,
  } = useGetDelivery(deliveryId, {
    revalidateOnFocus: false,
  });
  const {
    trigger: triggerPostFlierPointHistories,
    isMutating: isMutatingPostFlierPointHistories,
  } = usePostFlierPointHistories(deliveryId);
  const {
    trigger: triggerPostFavoriteDelivery,
    isMutating: isMutatingPostFavoriteDelivery,
  } = usePostFavoriteDelivery(deliveryId);
  const {
    trigger: triggerDeleteFavoriteDelivery,
    isMutating: isMutatingDeleteFavoriteDelivery,
  } = useDeleteFavoriteDelivery(deliveryId);
  const toast = useCustomToast();

  // 「チラシ詳細へ」ボタンクリック
  const onClickFlierDetailButton = () => {
    if (!delivery) return;
    // ユーザーがチラシの配信対象かつ click ポイントが未付与の場合ポイントを付与
    if (delivery.points && !delivery.points.click.is_granted) {
      triggerPostFlierPointHistories(
        { operation: "click" },
        {
          throwOnError: false, // 実行結果はどうでもいいので無視
          onSuccess: () =>
            mutate(
              (data = delivery) => ({
                ...data,
                points: data.points
                  ? {
                      ...data.points,
                      click: { ...data.points.click, is_granted: true },
                    }
                  : null,
              }),
              { revalidate: false } // 再検証は不要
            ),
        }
      );
    }
    // チラシ詳細の外部リンクを開く
    liff.openWindow({ url: delivery.flier_url, external: false });
  };

  // お気に入りボタンクリック
  const onClickFavoriteButton = () => {
    if (!delivery) return;
    delivery.is_favorite_delivery
      ? triggerDeleteFavoriteDelivery(null, {
          onSuccess: () => {
            toast("お気に入りから削除しました", { status: "success" });
            mutate(
              (data = delivery) => ({ ...data, is_favorite_delivery: false }),
              { revalidate: false } // 再検証は不要
            );
          },
        })
      : triggerPostFavoriteDelivery(
          {},
          {
            onSuccess: () => {
              toast("お気に入りに追加しました", { status: "success" });
              mutate(
                (data = delivery) => ({ ...data, is_favorite_delivery: true }),
                { revalidate: false } // 再検証は不要
              );
            },
          }
        );
  };

  // チラシ詳細の取得が成功し、ユーザーがチラシの配信対象かつ view ポイントが未付与の場合ポイントを付与する
  useEffect(() => {
    if (delivery && delivery.points && !delivery.points.view.is_granted)
      triggerPostFlierPointHistories(
        { operation: "view" },
        {
          throwOnError: false, // 実行結果はどうでもいいので無視
          onSuccess: () =>
            mutate(
              (data = delivery) => ({
                ...data,
                points: data.points
                  ? {
                      ...data.points,
                      view: { ...data.points.view, is_granted: true },
                    }
                  : null,
              }),
              { revalidate: false } // 再検証は不要
            ),
        }
      );
  }, [delivery, triggerPostFlierPointHistories, mutate]);

  // 読み込み中を表示 or チラシを取得できなかった場合は チラシ一覧 にもどってやり直し(404)
  if (!delivery)
    return isLoading ? (
      <Loading pageTitle="チラシ詳細" />
    ) : (
      <Navigate to="/fliers" />
    );

  return (
    // チラシ詳細
    <MainLayout
      header
      footer
      pageTitle={`${delivery.publisher.name}_${delivery.flier_name}`}
    >
      <Stack spacing="1rem" mt="1rem">
        {/* 広告主ロゴと広告主名 */}
        <Link
          as={RouterLink}
          to={`/fliers/publishers/${delivery.publisher.id}`}
          display="block"
          _hover={{
            textDecoration: "none",
          }}
        >
          <LogoWithText
            logoUrl={delivery.publisher.logo}
            text={delivery.publisher.name}
          />
        </Link>
        {/* チラシ画像一覧カルーセル */}
        <Carousel
          showThumbs={false} // react-zoom-pan-pinch との組み合わせでうまくいかないので false
          autoPlay={false}
          swipeable={false} // react-zoom-pan-pinch との組み合わせで操作性が悪くなるので false
          showIndicators={false} // react-zoom-pan-pinch との組み合わせで操作時に邪魔になるので false
          infiniteLoop
        >
          {delivery.flier_image_urls.map((url) => (
            // ズーム可能なチラシ画像(react-zoom-pan-pinch)
            <TransformWrapper key={url}>
              <TransformComponent
                // ズーム操作が可能な領域を親幅いっぱいまでとる(デフォルトの width: fit-content)
                contentStyle={{ width: "100%" }}
                wrapperStyle={{ width: "100%" }}
              >
                <Image
                  src={url}
                  alt={delivery.flier_name}
                  backgroundColor="System Gray/300"
                  width="full"
                  height="15rem"
                  objectFit="contain"
                />
              </TransformComponent>
            </TransformWrapper>
          ))}
        </Carousel>
        {/* 商品一覧 */}
        <FlierProducts items={delivery.items} />
        {/* チラシタイトル */}
        <Heading fontSize="xl" textAlign="left">
          {delivery.flier_name}
        </Heading>
      </Stack>

      {/* 下部固定領域 */}
      <Flex
        mt="2rem"
        position="sticky"
        top="100%"
        columnGap="1rem"
        alignItems="center"
      >
        {/* 「チラシ詳細へ」ボタン */}
        <Button
          flex={1}
          display="flex"
          columnGap="0.625rem"
          onClick={onClickFlierDetailButton}
          // 何かしら更新してる時は disabled にしておく
          isDisabled={
            isValidating ||
            isMutatingPostFlierPointHistories ||
            isMutatingPostFavoriteDelivery ||
            isMutatingDeleteFavoriteDelivery
          }
        >
          {/* 付与予定ポイント(click)バッジ（ユーザーがチラシの配信対象の場合のみ表示） */}
          {delivery.points && (
            <Badge
              px="0.5rem"
              py="0.25rem"
              display="flex"
              rowGap="0.25rem"
              alignItems="center"
              bg="System Gray/000"
              color="Primary/primary"
              borderRadius="full"
              fontWeight="bold"
            >
              <SelectedPointIcon boxSize="1.25rem" />
              {delivery.points.click.is_granted
                ? `獲得済み`
                : `${delivery.points.click.point.toLocaleString()}ポイント`}
            </Badge>
          )}
          チラシ詳細へ
        </Button>
        {/* お気に入り（ユーザーがチラシの配信対象の場合のみ表示） */}
        {delivery.points && (
          <Button
            variant="unstyled"
            width="auto"
            aria-label={`お気に入り${
              delivery.is_favorite_delivery ? "登録" : "削除"
            }`}
            onClick={onClickFavoriteButton}
            // 何かしら更新してる時は disabled にしておく
            isDisabled={
              isValidating ||
              isMutatingPostFlierPointHistories ||
              isMutatingPostFavoriteDelivery ||
              isMutatingDeleteFavoriteDelivery
            }
          >
            {delivery.is_favorite_delivery ? (
              <FavoriteOnIcon boxSize="2.8125rem" />
            ) : (
              <FavoriteOffIcon boxSize="2.8125rem" />
            )}
          </Button>
        )}
      </Flex>
    </MainLayout>
  );
};

export default Flier;
