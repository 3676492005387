import {
  Button,
  Divider,
  Grid,
  GridItem,
  Link,
  List,
  ListItem,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import { useGetPointHistories } from "api";
import MainLayout from "components/layouts/MainLayout";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import PointStat from "features/point/components/PointStat";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const Point: FC = () => {
  const navigate = useNavigate();
  const {
    data: { point_histories, total } = { point_histories: [], total: 0 },
  } = useGetPointHistories();

  return (
    <MainLayout header footer pageTitle="保有ポイント">
      <Stack spacing="1rem">
        {/* 保有ポイント */}
        <PointStat point={total} />
        {/* デジコに交換ボタン */}
        <Button variant="solid" onClick={() => navigate("/point/exchange/new")}>
          デジコに交換する
        </Button>
        {/* ポイント履歴リスト */}
        {point_histories.length > 0 && (
          <List>
            {point_histories.map((history) => {
              return (
                <ListItem key={history.id}>
                  {/* ポイント履歴内容 */}
                  <Grid
                    py="0.5rem"
                    templateAreas={`"operation created_at point" "id id point"`}
                    gridTemplateColumns="auto 1fr auto"
                    gridTemplateRows="auto 1fr"
                    alignItems="center"
                    columnGap="0.5rem"
                    rowGap="0.375rem"
                  >
                    {/* タグ */}
                    <GridItem
                      area="operation"
                      as={Tag}
                      minH="1.25rem"
                      px="0.25rem"
                      borderRadius="0.25rem"
                      {...(history.point >= 0
                        ? // 付与
                          {
                            color: "info",
                            backgroundColor: "rgba(36, 108, 216, 0.2)",
                          }
                        : // 利用
                          {
                            color: "danger",
                            backgroundColor: "rgba(221, 40, 40, 0.2)",
                          })}
                    >
                      {history.point >= 0 ? "付与" : "利用"}
                    </GridItem>

                    {/* 日時 */}
                    <GridItem
                      area="created_at"
                      as={Text}
                      fontSize="sm"
                      textColor="System Gray/700"
                    >
                      {dayjs(history.created_at).format("YYYY年MM月DD日 HH:mm")}
                    </GridItem>

                    {/* 内容 */}
                    <GridItem area="id">
                      {/* チラシ名 広告主名 */}
                      {history.flier_name && (
                        <Text fontSize="md" fontWeight="bold">
                          {history.flier_name} {history.publisher_name}
                        </Text>
                      )}
                      {/* デジココード */}
                      {history.digico_code && (
                        <>
                          <Text fontSize="md" fontWeight="bold">
                            {history.digico_code}
                          </Text>
                          {history.digico_url && (
                            <Link
                              fontSize="sm"
                              textDecoration="underline"
                              color="System Gray/500"
                              onClick={() =>
                                liff.openWindow({
                                  url: history.digico_url || "",
                                  external: true,
                                })
                              }
                            >
                              {history.digico_url}
                            </Link>
                          )}
                        </>
                      )}
                    </GridItem>

                    {/* ポイント */}
                    <GridItem
                      area="point"
                      display="flex"
                      alignItems="center"
                      columnGap="0.25rem"
                      color={history.point >= 0 ? "info" : "danger"}
                    >
                      <Text fontSize="xl" fontWeight="bold">
                        {history.point >= 0 && "+"}
                        {history.point.toLocaleString()}
                      </Text>
                      <Text fontSize="sm" fontWeight="bold">
                        ポイント
                      </Text>
                    </GridItem>
                  </Grid>
                  {/* 下線 */}
                  <Divider />
                </ListItem>
              );
            })}
          </List>
        )}
      </Stack>
    </MainLayout>
  );
};

export default Point;
