import { Box, Divider, Link, Text } from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import { ChevronRightIcon } from "assets/icons/Icons";
import MainLayout from "components/layouts/MainLayout";
import { UserMenuItem } from "features/user/types";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

const menuItems: UserMenuItem[] = [
  {
    id: "user",
    label: "会員情報の確認",
    path: "/user-menu/user",
  },
  {
    id: "faq",
    label: "よくある質問",
    path: "/user-menu/faq",
  },
  {
    id: "contact",
    label: "お問い合わせ",
    path: "/user-menu/contact",
  },
  {
    id: "about-funpost",
    label: "FunPostについて",
    path: "/user-menu/about-funpost",
  },
  {
    id: "user/withdraw",
    label: "退会はこちら",
    path: "/user-menu/user/withdraw",
  },
  {
    id: "terms",
    label: "利用規約",
    path: "https://funpost.fun/terms.html",
    isExternal: true,
  },
  {
    id: "privacy-policy",
    label: "プライバシーポリシー",
    path: "https://funpost.fun/privacy.html",
    isExternal: true,
  },
];

const UserMenu: FC = () => {
  return (
    <MainLayout header footer pageTitle="メニュー">
      {menuItems.map((item) => {
        return (
          <Box key={item.id}>
            {item.isExternal ? (
              <Link
                as="button"
                w="full"
                h="3rem"
                p="0.5rem"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                _hover={{
                  textDecoration: "none",
                }}
                onClick={() =>
                  liff.openWindow({ url: item.path, external: false })
                }
              >
                <Text>{item.label}</Text>
                <ChevronRightIcon w="0.375rem" h="0.75rem" />
              </Link>
            ) : (
              <Link
                as={RouterLink}
                to={item.path}
                h="3rem"
                p="0.5rem"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Text>{item.label}</Text>
                <ChevronRightIcon w="0.375rem" h="0.75rem" />
              </Link>
            )}
            <Divider />
          </Box>
        );
      })}
    </MainLayout>
  );
};

export default UserMenu;
