import { atom } from "recoil";
import { PostUsersRequestData } from "types/api";

/** UTM パラメータ記録用のステート */
export const utmParametersState = atom<
  Pick<
    PostUsersRequestData,
    "utm_campaign" | "utm_content" | "utm_medium" | "utm_source"
  >
>({
  key: "UtmParameters",
  default: {},
});
