import { Flex, Image, Text } from "@chakra-ui/react";
import { FC } from "react";

type LogoWithTextProps = {
  logoUrl: string;
  text: string;
};

const LogoWithText: FC<LogoWithTextProps> = ({ logoUrl, text }) => {
  return (
    <Flex columnGap="0.5rem" alignItems="center">
      <Image
        src={logoUrl}
        alt={text}
        boxSize="2rem"
        objectFit="contain"
        borderRadius="md"
        p="0.25rem"
        border="1px"
        borderColor="System Gray/300"
      />
      <Text fontSize="lg" fontWeight="bold" flex={1}>
        {text}
      </Text>
    </Flex>
  );
};

export default LogoWithText;
