import { Button, Image, List, ListItem, Text } from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import { FC } from "react";
import { Deliveries } from "types/api";

type FlierProductsProps = {
  items: Deliveries["deliveries"][number]["items"];
};

/** チラシの商品一覧 */
const FlierProducts: FC<FlierProductsProps> = ({ items }) => {
  if (items.length === 0) return null;
  return (
    <List
      display="flex"
      columnGap="1rem"
      overflowY="hidden"
      overflowX="scroll"
      sx={{
        // スクロールバーを表示しない
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {items.map(({ image, name, url }, index) => (
        <ListItem key={index}>
          <Button
            variant="unstyled"
            width="6.875rem"
            display="flex"
            flexDirection="column"
            columnGap="0.3125rem"
            alignItems="flex-start"
            onClick={() => liff.openWindow({ url, external: false })}
          >
            {/* 商品画像 */}
            <Image
              src={image}
              alt={name}
              // TODO: 画像表示について先方確認後要修正
              width="full"
              objectFit="contain" // どんな縦横比の画像でも全体が表示されるように
              border="1px"
              borderColor="System Gray/300"
              sx={{ aspectRatio: "2/1" }} // 縦横比 1:2
            />
            {/* 商品名 */}
            <Text fontSize="md" fontWeight="bold">
              {name}
            </Text>
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

export default FlierProducts;
