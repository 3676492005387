import { Flex, Text } from "@chakra-ui/react";
import { FC } from "react";

type PointStatProps = { point: number };

const PointStat: FC<PointStatProps> = ({ point }) => {
  return (
    <Flex
      p="1rem"
      borderColor="System Gray/300"
      borderWidth="1px"
      borderRadius="lg"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text color="System Gray/700">保有ポイント</Text>
      <Flex columnGap="0.25rem" alignItems="baseline">
        <Text fontSize="6xl" fontWeight="bold">
          {point.toLocaleString()}
        </Text>
        <Text>ポイント</Text>
      </Flex>
    </Flex>
  );
};

export default PointStat;
