import { Box, Container } from "@chakra-ui/react";
import Footer from "components/modules/Footer";
import Header from "components/modules/Header";
import { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";

type MainLayoutProps = {
  header?: boolean; // ヘッダ 表示・非表示管理
  footer?: boolean; // フッタ 表示・非表示管理
  pageTitle?: string;
  children: ReactNode;
};

const MainLayout: FC<MainLayoutProps> = ({
  header = true,
  footer = true,
  pageTitle,
  children,
}) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle ?? "FunPost"}</title>
      </Helmet>
      <Container
        bg="white"
        maxW="3xl" // 横幅に上限を設ける
        padding={0}
        height="100dvh"
        overflow="scroll"
      >
        {header && <Header />}
        <Box
          // フッターが画面の最下部に固定されるように min-height を指定 (100dvh - headerの高さ - footerの高さ)
          minH={`calc(100dvh${header ? " - 4rem" : ""}${
            footer ? " - 4rem" : ""
          })`}
          p="1rem"
        >
          {children}
        </Box>
        {footer && <Footer />}
      </Container>
    </>
  );
};
export default MainLayout;
