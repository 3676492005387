import { Button, Text } from "@chakra-ui/react";
import MainLayout from "components/layouts/MainLayout";
import { FUNPOST_DESCRIPTION } from "features/user/libs/texts";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const SignupTop: FC = () => {
  const navigate = useNavigate();

  return (
    <MainLayout header footer={false}>
      <Text>{FUNPOST_DESCRIPTION}</Text>
      <Button
        mt="2rem"
        position="sticky"
        top="100%"
        onClick={() => navigate("/signup/new")}
      >
        会員登録へ
      </Button>
    </MainLayout>
  );
};

export default SignupTop;
