import { Flex, Heading, Image, Link, Text } from "@chakra-ui/react";
import MainLayout from "components/layouts/MainLayout";
import { FC } from "react";
import line from "./../../assets/images/line.svg";

const PAGE_TITLE = "お問い合わせ";

const Contact: FC = () => {
  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE}>
      <Flex flexDirection="column" alignItems="center">
        <Heading fontSize="1rem">{PAGE_TITLE}</Heading>
        <Text mt="1rem" textAlign="center">
          FunPost LINE公式アカウントのメッセージより
          <br />
          お問い合わせください。
        </Text>
        <Link
          w="10rem"
          h="4.5rem"
          mt="2rem"
          p="1rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          border="solid #E0E0E0"
          borderRadius="0.5rem"
          _hover={{
            textDecoration: "none",
          }}
          href="https://lin.ee/0SwxTuv"
        >
          <Image src={line} boxSize="2.5rem" alt="LINE" loading="lazy" />
          <Text fontSize="xl" fontWeight="bold">
            FunPost
          </Text>
        </Link>
      </Flex>
    </MainLayout>
  );
};

export default Contact;
