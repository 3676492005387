import { extendTheme } from "@chakra-ui/react";
import { Button, Checkbox, Heading, Tag, Text } from "./components";

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: "black",
        bg: "white",
        fontWeight: 400,
      },
    },
  },
  components: {
    Button,
    Heading,
    Text,
    Checkbox,
    Tag,
  },
  fonts: {
    heading: "Inter, LineSeedJP, Noto Sans JP, system-ui, sans-serif",
    body: "Inter, LineSeedJP, Noto Sans JP, system-ui, sans-serif",
  },
  fontSizes: {
    sm: "0.75rem",
    md: "0.8125rem",
    lg: "1rem",
    xl: "1.125rem",
    "2xl": "1.25rem",
    "3xl": "1.5rem",
    "4xl": "1.75rem",
    "5xl": "2rem",
    "6xl": "2.25rem",
    "7xl": "2.5rem",
  },
  colors: {
    // Figma で定義されてる色
    "Primary/primary": "#85B656",
    "Primary/bg_primary": "#85B65633",
    "Secondary/secondary": "#B00E0E",
    "Secondary/bg-secondary": "#D7C06E33",
    "System Gray/1000": "#000000",
    "System Gray/700": "#616161",
    "System Gray/600": "#757575",
    "System Gray/500": "#9E9E9E  ",
    "System Gray/400": "#BDBDBD",
    "System Gray/300": "#E0E0E0",
    "System Gray/200": "#EEEEEE",
    "System Gray/100": "#F5F5F5",
    "System Gray/000": "#FFFFFF",
    // その他 Figma で利用されている色
    black: "#1D1D1B", // 基本の文字色(Figma 上は色々バリエーションありますが黒色の文字色は基本これを利用)
    white: "#ffffff",
    info: "#246CD8",
    danger: "#DD2828",
  },
});
