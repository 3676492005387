import { Container, Skeleton } from "@chakra-ui/react";
import MainLayout from "components/layouts/MainLayout";
import { FC } from "react";

type LoadingProps = {
  header?: boolean; // ヘッダ 表示・非表示管理
  pageTitle?: string;
};

/**
 * 画面全体のローディングコンポーネント
 */
const Loading: FC<LoadingProps> = ({ header = true, pageTitle }) => {
  return header ? (
    // ヘッダあり
    <MainLayout header footer={false} pageTitle={pageTitle}>
      {/* コンテンツ部分の Skeleton */}
      <Skeleton height="10rem" />
    </MainLayout>
  ) : (
    // ヘッダなし(ヘッダ部分を Skeleton)
    <Container maxW="3xl" padding={0}>
      {/* ヘッダ部分の Skeleton */}
      <Skeleton height="4rem" />
      {/* コンテンツ部分の Skeleton */}
      <Skeleton height="10rem" m="1rem" />
    </Container>
  );
};

export default Loading;
