/* eslint-disable sonarjs/no-duplicate-string */
import liff from "@line/liff/dist/lib";
import axios from "axios";
import { BASE_URL } from "libs/constants";
import { handleMoveToErrorPage } from "libs/handleError";
import useSWR, { SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import {
  CurrentUser,
  DeleteCurrentUserResponseData,
  DeleteFavoriteDeliveryResponseData,
  Deliveries,
  Delivery,
  Error,
  GetDeliveriesURLSearchParams,
  IsExistUnreadNotification,
  Notification,
  Notifications,
  PaperFlierGenre,
  PointHistories,
  PostCurrentPointHistoriesRequestData,
  PostCurrentPointHistoriesResponseData,
  PostCurrentPointsRequestData,
  PostCurrentPointsResponseData,
  PostFavoriteDeliveryRequestData,
  PostFavoriteDeliveryResponseData,
  PostFlierPointHistoriesRequestData,
  PostFlierPointHistoriesResponseData,
  PostReadNotificationsRequestData,
  PostReadNotificationsResponseData,
  PostUsersRequestData,
  PostUsersResponseData,
  PutUsersRequestData,
  PutUsersResponseData,
} from "types/api";

// axios instance
const instance = axios.create({
  baseURL: BASE_URL,
});
// interceptors request
instance.interceptors.request.use((config) => {
  if (!config.headers) config.headers = {};
  config.headers["Content-Type"] = "application/json; charset=UTF-8";
  config.headers["Access-Token"] = liff.getAccessToken() || "";
  config.headers["Token-Id"] = liff.getIDToken() || "";
  return config;
});
// interceptors response
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    switch (error.response?.status) {
      // 401
      case 401:
        if (
          error.response?.data?.errors?.includes("登録されていないユーザです")
        ) {
          // ユーザー未登録は登録ページへ
          !window.location.pathname.includes("/signup") &&
            window.location.replace(`/signup/top${window.location.search}`);
        } else {
          // トークン期限切れはエラーページへ
          localStorage.clear();
          handleMoveToErrorPage("session-expired");
        }
        break;
      // 500
      case 500: // Internal Server Error
      case 501: // Not Implemented
      case 502: // Bad Gateway
      case 503: // Service Unavailable
      case 504: // Gateway Timeout
      case 505: // HTTP Version Not Supported
      case 506: // Variant Also Negotiates
      case 507: // Insufficient Storage
      case 508: // Loop Detected
      case 510: // Not Extended
      case 511: // Network Authentication Required
        // エラーページへ
        handleMoveToErrorPage("server-error", error.response?.status);
        break;
    }
    return Promise.reject(error);
  }
);

// ---------- GET ----------
const fetcher = (path: string) =>
  instance
    .get(path)
    .then((res) => res.data.data) // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
    .catch((error) => Promise.reject(error.response.data)); // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す

// API002 会員情報取得
export const useGetCurrentUser = (options: SWRConfiguration = {}) =>
  useSWR<CurrentUser, Error>("/users/current", fetcher, options);

/** API002 会員情報取得:負荷を考慮し、アプリ起動時、一度だけ実行するために利用 */
export const useGetCurrentUserOnce = () =>
  useSWR<CurrentUser, Error>("/users/current", fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  });

// API005 チラシ一覧取得
export const useGetDeliveries = (
  urlSearchParams: GetDeliveriesURLSearchParams,
  options: SWRConfiguration = {}
) =>
  useSWR<Deliveries, Error>(
    `/users/current/deliveries?${new URLSearchParams(
      urlSearchParams
    ).toString()}`,
    fetcher,
    options
  );

// API006 チラシ詳細
export const useGetDelivery = (
  delivery_id: string,
  options: SWRConfiguration = {}
) =>
  useSWR<Delivery, Error>(
    `/users/current/deliveries/${delivery_id}`,
    fetcher,
    options
  );

// API013 ポイント履歴一覧取得
export const useGetPointHistories = () =>
  useSWR<PointHistories, Error>(`/users/current/point_histories`, fetcher);

// API015 チラシジャンル一覧
const getPaperFlierGenresFetcher = (path: string) =>
  fetcher(path)
    .then(
      (data): PaperFlierGenre =>
        // js で扱いやすいように data を加工
        data.reduce(
          (previousValue: PaperFlierGenre, currentValue: PaperFlierGenre) => ({
            ...previousValue,
            ...currentValue,
          }),
          {}
        )
    )
    .catch((error) => Promise.reject(error));

export const useGetPaperFlierGenres = (
  options: SWRConfiguration = {
    revalidateOnFocus: false, // 頻繁に変更されるデータではないので、フォーカス時の再検証は不要
  }
) =>
  useSWR<PaperFlierGenre, Error>(
    "/paper_flier_genres",
    getPaperFlierGenresFetcher,
    options
  );

// API016 お知らせ一覧取得
export const useGetNotifications = (options: SWRConfiguration = {}) =>
  useSWR<Notifications, Error>(
    "/users/current/notifications",
    fetcher,
    options
  );

// API017 お知らせ詳細取得
export const useGetNotification = (
  notification_id: string,
  options: SWRConfiguration = {}
) =>
  useSWR<Notification, Error>(
    `/users/current/notifications/${notification_id}`,
    fetcher,
    options
  );

// API019 未読お知らせ有無取得
export const useGetIsExistUnreadNotification = (
  options: SWRConfiguration = {}
) =>
  useSWR<IsExistUnreadNotification, Error>(
    "/users/current/notifications/unread",
    fetcher,
    options
  );

// ---------- POST ----------
const fetcherForPOST = (path: string, { arg }: { arg: object }) =>
  instance
    .post(path, arg)
    .then((res) => res.data.data) // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
    .catch((error) => Promise.reject(error.response.data)); // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す

// API001 会員登録
export const usePostUsers = () =>
  useSWRMutation<
    PostUsersResponseData, // data
    Error, // error
    string, // key
    PostUsersRequestData // リクエストボディ
  >("/users", fetcherForPOST);

// API008 お気に入りチラシ追加
export const usePostFavoriteDelivery = (delivery_id: string) =>
  useSWRMutation<
    PostFavoriteDeliveryResponseData, // data
    Error, // error
    string, // key
    PostFavoriteDeliveryRequestData // リクエストボディ
  >(`/users/current/favorite_deliveries/${delivery_id}`, fetcherForPOST);

// API011 ポイント付与
export const usePostFlierPointHistories = (delivery_id: string) =>
  useSWRMutation<
    PostFlierPointHistoriesResponseData, // data
    Error, // error
    string, // key
    PostFlierPointHistoriesRequestData // リクエストボディ
  >(`/deliveries/${delivery_id}/point_histories`, fetcherForPOST);

// API012 残高照会
export const usePostCurrentPoints = () =>
  useSWRMutation<
    PostCurrentPointsResponseData, // data
    Error, // error
    string, // key
    PostCurrentPointsRequestData // リクエストボディ
  >("/users/current/points", fetcherForPOST);

// API014 ポイント交換
export const usePostCurrentPointHistories = () =>
  useSWRMutation<
    PostCurrentPointHistoriesResponseData, // data
    Error, // error
    string, // key
    PostCurrentPointHistoriesRequestData // リクエストボディ
  >("/users/current/point_histories", fetcherForPOST);

// API018 お知らせ既読
export const usePostReadNotifications = () =>
  useSWRMutation<
    PostReadNotificationsResponseData, // data
    Error, // error
    string, // key
    PostReadNotificationsRequestData // リクエストボディ
  >("/users/current/notifications/unread", fetcherForPOST);

// ---------- PUT ----------
const fetcherForPUT = (path: string, { arg }: { arg: object }) =>
  instance
    .put(path, arg)
    .then((res) => res.data.data) // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
    .catch((error) => Promise.reject(error.response.data)); // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す

// API003 ユーザー情報更新
export const usePutCurrentUser = () =>
  useSWRMutation<
    PutUsersResponseData, // data
    Error, // error
    string, // key
    PutUsersRequestData // リクエストボディ
  >("/users/current", fetcherForPUT);

// ---------- DELETE ----------
const fetcherForDELETE = (path: string) =>
  instance
    .delete(path)
    .then((res) => res.data.data) // レスポンスボディ { "status": "succeeded", "data": データの中身 } の "data" 部を返す
    .catch((error) => Promise.reject(error.response.data)); // レスポンスボディ { "status": "failed", "errors": [エラー内容の配列] } 自体を返す

// API004 ユーザー退会
export const useDeleteCurrentUser = () =>
  useSWRMutation<
    DeleteCurrentUserResponseData, // data
    Error, // error
    string // key
  >(`/users/current`, fetcherForDELETE);

// API009 お気に入りチラシ削除
export const useDeleteFavoriteDelivery = (delivery_id: string) =>
  useSWRMutation<
    DeleteFavoriteDeliveryResponseData, // data
    Error, // error
    string // key
  >(`/users/current/favorite_deliveries/${delivery_id}`, fetcherForDELETE);
