import { Button, Heading } from "@chakra-ui/react";
import { useGetCurrentUser, useGetPaperFlierGenres } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import UserValues from "features/user/components/UserValues";
import { FC } from "react";
import { Link } from "react-router-dom";

const PAGE_TITLE = "会員情報";

const User: FC = () => {
  const { data, isValidating } = useGetCurrentUser();
  const { data: paperFlierGenres } = useGetPaperFlierGenres();

  if (isValidating || !data || !paperFlierGenres)
    return <Loading pageTitle={PAGE_TITLE} />;

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE}>
      <Heading size="md">{PAGE_TITLE}</Heading>
      {/* 会員情報 */}
      <UserValues
        values={{
          ...data,
          paper_flier_genres: data.paper_media
            .map((genre) => paperFlierGenres[genre])
            .filter(Boolean),
        }}
      />
      {/* 変更ボタン */}
      <Button
        as={Link}
        mt="2rem"
        position="sticky"
        top="100%"
        to="/user-menu/user/edit"
      >
        変更する
      </Button>
    </MainLayout>
  );
};

export default User;
