import { Text } from "@chakra-ui/react";
import MainLayout from "components/layouts/MainLayout";
import { FUNPOST_DESCRIPTION } from "features/user/libs/texts";
import { FC } from "react";

const AboutFunPost: FC = () => {
  return (
    <MainLayout header footer={false} pageTitle="FunPost について">
      <Text>{FUNPOST_DESCRIPTION}</Text>
    </MainLayout>
  );
};

export default AboutFunPost;
