import {
  Center,
  Divider,
  Link,
  List,
  ListItem,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useGetDeliveries } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import LogoWithText from "components/modules/LogoWithText";
import FliersItem from "features/flier/components/FliersItem";
import { FC } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { GetDeliveriesURLSearchParams } from "types/api";
import { number } from "yup";

// 絞り込みタブ一覧
const TAB_LIST: {
  label: string;
  searchParams: GetDeliveriesURLSearchParams;
}[] = [
  { label: "すべて", searchParams: { type: "all" } },
  { label: "おすすめ", searchParams: { type: "recommendation" } },
  { label: "お気に入り", searchParams: { type: "favorite" } },
  { label: "自治体からのお知らせ", searchParams: { type: "municipality" } },
];

const Fliers: FC = () => {
  // 選択された絞り込みタブをクエリパラメータ tabIndex で管理
  const [searchParams, setSearchParams] = useSearchParams({ tabIndex: "0" });
  const tabIndex = number()
    .transform((val) =>
      // 0 ~ 3 の数字を強制
      typeof val === "number" && val >= 0 && val < TAB_LIST.length ? val : 0
    )
    .required()
    .cast(searchParams.get("tabIndex"));

  const pageTitle = `ポスト ${TAB_LIST[tabIndex].label}`;

  // API005 チラシ一覧取得
  const { data: { deliveries } = { deliveries: [] }, isValidating } =
    useGetDeliveries(TAB_LIST[tabIndex].searchParams, {
      revalidateOnFocus: false,
    });

  if (isValidating) return <Loading pageTitle={pageTitle} />;

  return (
    <MainLayout header footer pageTitle={pageTitle}>
      <Stack spacing="1rem">
        {/* 絞り込みタブ */}
        <Tabs
          variant="unstyled"
          index={tabIndex}
          onChange={(index) => setSearchParams({ tabIndex: `${index}` })}
        >
          <TabList
            bg="Primary/bg_primary"
            mx="-1rem" // MainLayout の左右パディングを打ち消す
            px="1rem"
            py="0.5rem"
            columnGap="0.5rem"
            // 横スクロールできるように 参考: https://github.com/chakra-ui/chakra-ui/issues/1852#issuecomment-987815619
            overflowY="hidden"
            overflowX="scroll"
            sx={{
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {TAB_LIST.map(({ label }, index) => (
              <Tab
                key={index}
                bg="System Gray/000"
                px="1rem"
                py="0.25rem"
                color="Primary/primary"
                fontWeight="bold"
                borderRadius="full"
                wordBreak="keep-all"
                _selected={{
                  bg: "Primary/primary",
                  color: "System Gray/000",
                }}
              >
                {label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        {/* チラシ一覧 */}
        {deliveries.length == 0 ? (
          // チラシが1件もないとき
          <Center>
            <Text fontSize="lg">チラシが1件もありません</Text>
          </Center>
        ) : (
          // チラシが1件以上あるとき
          <List spacing="1rem">
            {deliveries.map(
              ({
                publisher,
                flier_name,
                delivery_id,
                flier_image_urls,
                items,
                points,
              }) => (
                <ListItem key={delivery_id}>
                  <Stack spacing="1rem">
                    {/* 広告主ロゴと広告主名 */}
                    <Link
                      as={RouterLink}
                      to={`/fliers/publishers/${publisher.id}`}
                      display="block"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <LogoWithText
                        logoUrl={publisher.logo}
                        text={publisher.name}
                      />
                    </Link>
                    {/* チラシ本体 */}
                    <FliersItem
                      {...{
                        delivery_id,
                        flier_name,
                        flier_image_urls,
                        items,
                        points,
                      }}
                    />
                    {/* 区切り線 */}
                    <Divider />
                  </Stack>
                </ListItem>
              )
            )}
          </List>
        )}
      </Stack>
    </MainLayout>
  );
};

export default Fliers;
