import { Flex, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

type FlexLabeledValueProps = {
  label: string;
  value: string | ReactNode;
};

const FlexLabeledValue: FC<FlexLabeledValueProps> = ({ label, value }) => {
  return (
    <Flex columnGap="1rem">
      <Text w="6.25rem" fontWeight="bold" color="System Gray/500">
        {label}
      </Text>
      <Text flex={1}>{value}</Text>
    </Flex>
  );
};

export default FlexLabeledValue;
