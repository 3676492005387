import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useDeleteCurrentUser, useGetCurrentUserOnce } from "api";
import { XCircleIcon } from "assets/icons/Icons";
import MainLayout from "components/layouts/MainLayout";
import { useCustomToast } from "hooks/useCustomToast";
import { FC, useRef } from "react";
import { useAsyncFn } from "react-use";

const PAGE_TITLE = "退会";

const UserWithdraw: FC = () => {
  const { trigger } = useDeleteCurrentUser();
  const { mutate: mutateGetCurrentUserOnce } = useGetCurrentUserOnce();
  const toast = useCustomToast();

  // 退会確認用ダイアログ管理
  const { isOpen, onOpen, onClose } = useDisclosure();
  // 退会確認用ダイアログで最初にフォーカスを当てる要素
  const closeRef = useRef(null);

  // 退会実行ボタンクリック時の処理 ※ダブルクリック対策のため useAsyncFn を利用して処理が完全に終了するまでの状態を管理
  const [{ loading: isMutating }, onClickWithdrawButton] = useAsyncFn(
    () =>
      trigger(null, {
        throwOnError: true, // 成功か失敗を判断するため true
        revalidate: false, // キーが一緒なので再検証は自動で行われるが、その他必要な処理と合わせて行いたいので false
        // 失敗時の処理はここで行う(reject で受け取るエラーには型がつかないためしょうがなく)
        onError: ({ errors }) => toast(errors, { status: "error" }), // トーストエラー
      }).then(
        // 成功時
        () => mutateGetCurrentUserOnce() // 会員情報を再検証
      ),
    [trigger]
  );

  return (
    <>
      <MainLayout header footer={false} pageTitle={PAGE_TITLE}>
        <Text>
          以下、注意事項をご確認ください。
          <br />
          <br />
          ※ご登録の会員情報を削除し、もとに戻すことはできません。
          <br />
          ※獲得済みのポイントは失効し、もとに戻すことはできません。
          <br />
          ※FunPostのLINE公式アカウントを友だち登録している場合、友だちは解除されません。
        </Text>
        <Button
          mt="2rem"
          variant="solid"
          position="sticky"
          top="100%"
          onClick={onOpen}
        >
          退会する
        </Button>
      </MainLayout>
      {/* 退会確認用ダイアログ */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={closeRef}
        onClose={onClose}
        closeOnOverlayClick={!isMutating}
        size="xs"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent borderRadius="1.25rem">
          <AlertDialogCloseButton
            ref={closeRef}
            position="static"
            width="1.125rem"
            height="1.125rem"
            mt="1rem"
            mr="1rem"
            alignSelf="flex-end"
            isDisabled={isMutating}
          >
            <XCircleIcon boxSize="full" color="System Gray/400" />
          </AlertDialogCloseButton>
          <AlertDialogHeader textAlign="center" fontWeight="bold">
            本当に退会しますか？
          </AlertDialogHeader>
          <AlertDialogBody fontSize="md">
            注意事項をご確認の上、退会しますか？
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button isLoading={isMutating} onClick={onClickWithdrawButton}>
              退会する
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UserWithdraw;
