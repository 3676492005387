import { Button, Heading, Stack } from "@chakra-ui/react";
import { usePostUsers } from "api";
import MainLayout from "components/layouts/MainLayout";
import UserValues from "features/user/components/UserValues";
import { convertUserFormInputs } from "features/user/libs/paperFlierGenres";
import { SignupConfirmLocation } from "features/user/types";
import { useCustomToast } from "hooks/useCustomToast";
import { utmParametersState } from "libs/recoil/atom";
import { FC } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";

const PAGE_TITLE = "会員登録";

const SignupConfirm: FC = () => {
  const { pathname, state }: SignupConfirmLocation = useLocation();
  const navigate = useNavigate();
  const toast = useCustomToast();
  const { trigger } = usePostUsers();
  const utmParameters = useRecoilValue(utmParametersState);

  // 登録処理 ※ダブルクリック対策のため useAsyncFn を利用して処理が完全に終了するまでの状態を管理
  const [{ loading: isMutating }, onSubmit] = useAsyncFn(async () => {
    if (!state) return;
    await trigger(
      { ...convertUserFormInputs(state).submitValues, ...utmParameters },
      {
        throwOnError: true, // 成功か失敗を判断するため true
        // 失敗時の処理はここで行う(reject で受け取るエラーには型がつかないためしょうがなく)
        onError: ({ errors }) => toast(errors, { status: "error" }), // トーストエラー
      }
    );
    navigate(pathname, { replace: true }); // state を削除
    navigate("/signup/complete"); // 完了ページへ
  }, [state, trigger, convertUserFormInputs]);

  // state が正しく取得できない場合は /signup/new にもどってやり直し
  if (!state) return <Navigate to="/signup/new" />;

  const { confirmValues } = convertUserFormInputs(state);

  const onEdit = () => {
    navigate("/signup/new", { state: state });
  };

  return (
    <MainLayout header footer={false} pageTitle={PAGE_TITLE}>
      <Heading fontSize="1rem">{PAGE_TITLE}</Heading>

      <UserValues values={confirmValues} />

      <Stack w="100%" mt="2rem" position="sticky" top="100%" spacing="1rem">
        <Button isLoading={isMutating} onClick={onSubmit}>
          登録する
        </Button>
        <Button variant="outline" isLoading={isMutating} onClick={onEdit}>
          修正する
        </Button>
      </Stack>
    </MainLayout>
  );
};
export default SignupConfirm;
