import { Badge, Box, Image, Stack, Text } from "@chakra-ui/react";
import { SelectedPointIcon } from "assets/icons/Icons";
import FlierProducts from "features/flier/components/FlierProducts";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Deliveries } from "types/api";

type FliersItemProps = Pick<
  Deliveries["deliveries"][number],
  "delivery_id" | "flier_name" | "flier_image_urls" | "items" | "points"
>;

/** チラシ画像（上半分）・チラシタイトル・付与予定ポイント数・製品画像 のコンポーネント */
const FliersItem: FC<FliersItemProps> = ({
  delivery_id,
  flier_name,
  flier_image_urls,
  items,
  points,
}) => {
  return (
    <Stack spacing="0.5rem">
      {/* チラシ画像 */}
      <Box
        as={RouterLink}
        to={`/fliers/${delivery_id}`}
        height="12rem"
        display="block"
        bgGradient="linear(to-t, black, white)"
        position="relative"
      >
        {/* 画像 */}
        {flier_image_urls[0] && (
          <Image
            src={flier_image_urls[0]}
            alt={flier_name}
            width="full"
            height="full"
            objectFit="cover"
            objectPosition="center top" // 上部中央
            opacity="0.5"
          />
        )}
        {/* タイトル */}
        <Text
          position="absolute"
          bottom="0.5rem"
          left="0.5rem"
          color="white"
          fontWeight="bold"
        >
          {flier_name}
        </Text>
        {/* 付与予定ポイント(view)バッジ */}
        {!points.view.is_granted && (
          <Badge
            position="absolute"
            right="0.5rem"
            top="0.5rem"
            px="0.5rem"
            py="0.25rem"
            display="flex"
            rowGap="0.25rem"
            alignItems="center"
            bg="System Gray/000"
            color="Primary/primary"
            border="1px"
            borderColor="System Gray/300"
            borderRadius="full"
            fontWeight="bold"
          >
            <SelectedPointIcon boxSize="1.25rem" />
            {points.view.point.toLocaleString()}ポイント
          </Badge>
        )}
      </Box>
      {/* 商品一覧 */}
      <FlierProducts items={items} />
    </Stack>
  );
};

export default FliersItem;
