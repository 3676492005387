import { Box, Image, Link } from "@chakra-ui/react";
import FunPost_logo from "assets/images/FunPost_logo.svg";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

const Header: FC = () => {
  return (
    <Box
      as="header"
      w="100%"
      h="3rem"
      mb="1rem" // ロゴ部分がはみ出る分だけ下側にマージンを確保 (4rem - 3rem)
      bg="white"
      boxShadow="0px 10px 10px rgba(0, 0, 0, 0.1)"
      position="sticky"
      top={0}
      zIndex={1}
    >
      {/* ロゴ */}
      <Link
        as={RouterLink}
        to="/fliers"
        bg="white"
        h="4rem"
        w="4rem"
        rounded="full"
        mx="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        _hover={{}}
      >
        {/* ロゴ画像 */}
        <Image
          src={FunPost_logo}
          borderRadius="full"
          boxSize="2rem"
          alt="Logo"
        />
      </Link>
    </Box>
  );
};

export default Header;
