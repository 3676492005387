import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Image,
  Link,
  List,
  ListItem,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useGetNotifications, usePostReadNotifications } from "api";
import MainLayout from "components/layouts/MainLayout";
import Loading from "components/modules/Loading";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useCustomToast } from "hooks/useCustomToast";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const PAGE_TITLE = "お知らせ一覧";

const Notifications: FC = () => {
  const {
    data: { notifications } = { notifications: [] },
    isValidating,
    mutate,
  } = useGetNotifications({
    revalidateOnFocus: false,
  });
  const {
    trigger: triggerPostReadNotifications,
    isMutating: isMutatingPostReadNotifications,
  } = usePostReadNotifications();
  const toast = useCustomToast();

  const onClickReadAllNotifications = () => {
    const unreadNotifications = notifications.filter(
      (notification) => !notification.read
    );

    if (unreadNotifications.length === 0) return;

    triggerPostReadNotifications(
      {
        notification_ids: unreadNotifications.map(
          (notification) => notification.id
        ),
      },
      {
        throwOnError: true,
        onSuccess: () => {
          // 成功時のトーストメッセージ
          toast("すべてのお知らせを既読にしました", { status: "success" });
          // API016 お知らせ一覧取得API を再検証して未読状態を更新
          mutate();
        },
        onError: ({ errors }) =>
          // トーストエラー
          toast(errors, { status: "error" }),
      }
    );
  };

  if (isValidating) return <Loading pageTitle={PAGE_TITLE} />;

  if (notifications.length === 0)
    return (
      <MainLayout header footer pageTitle={PAGE_TITLE}>
        <Text textAlign="center" fontSize="lg">
          お知らせが1件もありません
        </Text>
      </MainLayout>
    );

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE}>
      <Box textAlign="end">
        <Button
          width="fit-content"
          padding="0"
          fontWeight="bold"
          color="Primary/primary"
          backgroundColor="unset"
          onClick={() => onClickReadAllNotifications()}
          isLoading={isMutatingPostReadNotifications}
        >
          すべて既読にする
        </Button>
      </Box>

      <List mt="1rem">
        {notifications.map((notification) => (
          <ListItem key={notification.id}>
            <Link
              as={RouterLink}
              to={`/notifications/${notification.id}`}
              _hover={{
                textDecoration: "none",
              }}
            >
              <Grid
                py="0.5rem"
                templateAreas={`"is_read created_at image" "title title image"`}
                gridTemplateColumns="auto 1fr auto"
                gridTemplateRows="auto 1fr"
                alignItems="center"
                rowGap="0.375rem"
              >
                {/* タグ */}
                {!notification.read && (
                  <GridItem
                    area="is_read"
                    as={Tag}
                    minH="1.25rem"
                    mr="0.5rem"
                    rowGap="0.375rem"
                    px="0.25rem"
                    borderRadius="0.25rem"
                    color="danger"
                    backgroundColor="rgba(221, 40, 40, 0.2)"
                  >
                    未読
                  </GridItem>
                )}

                {/* 日時 */}
                <GridItem
                  area="created_at"
                  as={Text}
                  fontSize="sm"
                  textColor="System Gray/700"
                >
                  {dayjs(notification.published_at).format(
                    "YYYY年MM月DD日 HH:mm"
                  )}
                </GridItem>

                {/* お知らせタイトル */}
                <GridItem area="title" as={Text} fontWeight="bold">
                  {notification.title}
                </GridItem>

                {/* お知らせ画像 */}
                {notification.image_filename && (
                  <GridItem
                    area="image"
                    as={Image}
                    w="3.75rem"
                    h="3.75rem"
                    ml="0.5rem"
                    objectFit="contain"
                    src={notification.image_filename}
                    alt={`お知らせ画像${notification.id}`}
                  />
                )}
              </Grid>

              {/* 下線 */}
              <Divider />
            </Link>
          </ListItem>
        ))}
      </List>
    </MainLayout>
  );
};

export default Notifications;
