import { Stack, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

type LabeledValueProps = {
  label: string;
  value: string | ReactNode;
};

const LabeledValue: FC<LabeledValueProps> = ({ label, value }) => {
  return (
    <Stack w="100%" spacing="0.5rem">
      <Text fontSize="0.8125rem" fontWeight="bold">
        {label}
      </Text>
      <Text fontSize="1rem">{value}</Text>
    </Stack>
  );
};

export default LabeledValue;
