import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useGetPointHistories, usePostCurrentPoints } from "api";
import MainLayout from "components/layouts/MainLayout";
import AboutDigicoCard from "features/point/components/AboutDigicoCard";
import PointStat from "features/point/components/PointStat";
import { ExchangeFormInputs } from "features/point/types";
import { useCustomToast } from "hooks/useCustomToast";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const PointExchangeNew: FC = () => {
  const navigate = useNavigate();
  const { data: { total } = { total: 0 } } = useGetPointHistories();
  const { trigger } = usePostCurrentPoints();
  const toast = useCustomToast();

  // 交換ポイント入力フォーム
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ExchangeFormInputs>({ mode: "onChange" });

  // 交換ポイント入力フォーム submit ※ダブルクリック対策のため Promise を返し、全ての処理が完全に終了するまで resolve させない
  const onSubmit: SubmitHandler<ExchangeFormInputs> = (data) =>
    // API012 残高照会の実行
    trigger(data, {
      throwOnError: true, // 成功か失敗を判断するため true
      // 失敗時の処理はここで行う(reject で受け取るエラーには型がつかないためしょうがなく)
      onError: ({ errors }) => toast(errors, { status: "error" }), // トーストエラー
    })
      .then(
        // 成功時
        (currentPoints) =>
          navigate("/point/exchange/confirm", { state: { ...currentPoints } }) // 確認ページへ
      )
      .catch(
        // 失敗時 ※react-hook-form は reject 時に isSubmitting を true に戻してくれないので必ず catch する
        () => {
          /* 何もしない */
        }
      );

  return (
    <MainLayout header pageTitle="ポイント交換">
      <Stack spacing="1rem">
        {/* 保有ポイント */}
        <PointStat point={total} />
        {/* デジコについて */}
        <AboutDigicoCard />
        {/* 交換ポイント入力フォーム */}
        <Stack as="form" spacing="1rem" onSubmit={handleSubmit(onSubmit)}>
          {/* 説明と注釈 */}
          <Stack spacing="1rem">
            <Text fontSize="xl" fontWeight="bold">
              交換したいポイントを入力してください
            </Text>
            <Text fontSize="xs" color="System Gray/600">
              ※一度交換申し込みしたポイントのキャンセルはできません。
              <br />
              ※10ポイントで1円相当のデジコと交換できます。
            </Text>
          </Stack>
          {/* 交換ポイント入力欄 */}
          <FormControl isInvalid={!!errors.points}>
            <FormLabel mb="0.25rem" fontWeight="bold">
              交換ポイント
            </FormLabel>
            {/* input */}
            <Input
              type="number"
              placeholder="2000"
              {...register("points", {
                valueAsNumber: true, // number
                required: true, // 必須
                min: 2000, // 2000 ポイント以上
                max: total, // 保有ポイント以下
                validate: (value) => !!value && value % 10 === 0, // 10 ポイント単位
              })}
            />
            {/* エラー内容 */}
            {errors.points && (
              <FormErrorMessage fontSize="xs" mt="0.25rem">
                交換ポイントは2,000ポイント以上かつ保有ポイント以下で10ポイント単位で入力してください。
              </FormErrorMessage>
            )}
            {/* 注釈 */}
            <FormHelperText fontSize="xs" color="System Gray/600" mt="0.25rem">
              ※2,000ポイントから交換できます。
              <br />
              ※10ポイントごとに入力してください。
              <br />
              ※10ポイントはデジコ1円相当です。
            </FormHelperText>
          </FormControl>
          {/* 確認ボタン */}
          <Button isDisabled={!isValid} isLoading={isSubmitting} type="submit">
            確認する
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default PointExchangeNew;
