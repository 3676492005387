import { Box } from "@chakra-ui/react";
import { BadgeIcon } from "assets/icons/Icons";
import { FC } from "react";

type IconWithBadgeProps = {
  icon: JSX.Element;
  hasBadge: boolean;
};

const IconWithBadge: FC<IconWithBadgeProps> = ({ icon, hasBadge }) => {
  return (
    <Box position="relative">
      {hasBadge && (
        <BadgeIcon
          width="11px"
          height="11px"
          position="absolute"
          right="0px"
          data-testid="badge"
        />
      )}
      {icon}
    </Box>
  );
};

export default IconWithBadge;
