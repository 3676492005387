import { UserFormInputs, UserSubmits } from "../types";

/**
 * 選択した「紙で受け取りたいチラシ」のName（チラシジャンル名）を取得する
 * @param {UserFormInputs}userFormInputs
 * @returns {UserFormInputs}
 */
const generateConfirmValues = (
  userFormInputs: UserFormInputs
): UserFormInputs => {
  const genresStr = userFormInputs?.paper_flier_genres;
  const genreNames = genresStr?.map((genreStr) => {
    const genre = JSON.parse(genreStr);
    return String(Object.values(genre)[0]);
  });

  return {
    ...userFormInputs,
    paper_flier_genres: genreNames || [],
  };
};

/**
 * 選択した「紙で受け取りたいチラシ」のKeyを取得する
 * @param {UserFormInputs}userFormInputs
 * @returns {UserSubmits}
 */
const generateSubmitValues = (userFormInputs: UserFormInputs): UserSubmits => {
  const genresStr = userFormInputs?.paper_flier_genres;
  const genreKeys = genresStr?.map((genreStr) => {
    const genre = JSON.parse(genreStr);
    return Number(Object.keys(genre)[0]);
  });

  return {
    ...userFormInputs,
    postal_code: `${userFormInputs.postal_code.slice(
      0,
      3
    )}-${userFormInputs.postal_code.slice(3)}`,
    paper_flier_genres: genreKeys || [],
  };
};

/**
 * 選択した「紙で受け取りたいチラシ」をKeyとNameに分ける
 * - ユーザー登録APIのpaper_flier_genres: []にはKeyの数値を渡す
 * cf.https://vg-ceg.backlog.com/view/DIGICLUE_LINEMINIAPP-2504
 * - Nameは確認ページで利用する
 * @param {UserFormInputs} userFormInputs
 * @returns { confirmValues: UserInputs; submitValues: UserSubmit }
 */
export const convertUserFormInputs = (
  userFormInputs: UserFormInputs
): { confirmValues: UserFormInputs; submitValues: UserSubmits } => {
  const confirmValues = generateConfirmValues(userFormInputs);
  const submitValues = generateSubmitValues(userFormInputs);

  return {
    confirmValues,
    submitValues,
  };
};
