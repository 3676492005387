import { Link, List, ListItem, Stack, Text } from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import { useGetDeliveries } from "api";
import MainLayout from "components/layouts/MainLayout";
import FlexLabeledValue from "components/modules/FlexLabeledValue";
import Loading from "components/modules/Loading";

import LogoWithText from "components/modules/LogoWithText";
import FliersItem from "features/flier/components/FliersItem";
import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";

const PAGE_TITLE = "広告主のサマリー";

const FliersOfPublisher: FC = () => {
  const { publisherId = "" } = useParams();
  const { data: { deliveries } = { deliveries: [] }, isLoading } =
    useGetDeliveries(
      { type: "publisher", publisher_id: publisherId },
      { revalidateOnFocus: false }
    );

  if (isLoading) return <Loading pageTitle={PAGE_TITLE} />;

  // 広告主ごとのチラシ一覧が0件だった場合は お知らせ一覧 にリダイレクト
  // *存在しない publisherId の中で桁数が異なる場合は500エラーになり、桁数が同じ場合は0件になる（publisherId の桁数は uuid32桁）
  if (deliveries.length === 0) return <Navigate to="/fliers" />;

  return (
    <MainLayout header footer pageTitle={PAGE_TITLE}>
      <Stack spacing="1.5rem">
        {/* 広告主ロゴと広告主名 */}
        <LogoWithText
          logoUrl={deliveries[0].publisher.logo}
          text={deliveries[0].publisher.name}
        />

        {/* チラシ一覧 */}
        <Stack spacing="1rem">
          <Text fontSize="lg" fontWeight="bold">
            チラシを見る
          </Text>
          <List spacing="1rem">
            {deliveries.map(
              ({
                delivery_id,
                flier_name,
                flier_image_urls,
                items,
                points,
              }) => (
                <ListItem key={delivery_id}>
                  <FliersItem
                    {...{
                      delivery_id,
                      flier_name,
                      flier_image_urls,
                      items,
                      points,
                    }}
                  />
                </ListItem>
              )
            )}
          </List>
        </Stack>

        {/* 広告主のその他情報 */}
        <Stack spacing="0.75rem">
          <FlexLabeledValue
            label="住所"
            value={deliveries[0].publisher.address}
          />
          <FlexLabeledValue
            label="電話番号"
            value={deliveries[0].publisher.phone}
          />
          <FlexLabeledValue
            label="ホームページ"
            value={
              <Link
                as="button"
                textAlign="start"
                onClick={() =>
                  liff.openWindow({
                    url: deliveries[0].publisher.url,
                    external: false,
                  })
                }
              >
                {deliveries[0].publisher.url}
              </Link>
            }
          />
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default FliersOfPublisher;
