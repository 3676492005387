import { ChakraProvider } from "@chakra-ui/react";
import liff from "@line/liff/dist/lib";
import { theme } from "libs/chakra";
import { handleMoveToErrorPage } from "libs/handleError";
import Error from "pages/Error";
import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import "./assets/styles/App.scss";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

if (window.location.pathname !== "/error") {
  liff
    .init({
      liffId: process.env.REACT_APP_LIFF_ID || "",
      withLoginOnExternalBrowser: true,
    })
    .then(() => {
      root.render(
        <React.StrictMode>
          <HelmetProvider>
            <ChakraProvider theme={theme}>
              <App />
            </ChakraProvider>
          </HelmetProvider>
        </React.StrictMode>
      );
    })
    .catch((err) => {
      console.error("Error Initializing LIFF: ", err.message);
      localStorage.clear();

      handleMoveToErrorPage("init-liff-failure");
    });
} else {
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <ChakraProvider theme={theme}>
          <Error />
        </ChakraProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
