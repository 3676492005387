import { VStack } from "@chakra-ui/react";
import LabeledValue from "components/modules/LabeledValue";
import { UserFormInputs } from "features/user/types";
import { FC } from "react";

type UserValuesProps = { values: UserFormInputs };

const UserValues: FC<UserValuesProps> = ({ values }) => {
  return (
    <VStack spacing="1.5rem" mt="1rem" alignItems="start">
      <LabeledValue
        label="お名前（表札のお名前）"
        value={`${values.last_name} ${values.first_name}`}
      />
      <LabeledValue label="郵便番号" value={values.postal_code} />
      <LabeledValue
        label="住所"
        value={`${values.prefecture}${values.municipalities}${values.after_address}${values.building}`}
      />
      <LabeledValue
        label="紙で受け取りたいチラシ"
        value={values.paper_flier_genres.join(" / ")}
      />
    </VStack>
  );
};

export default UserValues;
